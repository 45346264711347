<template>
    <div></div>
</template>
<script>
    export default {
        computed : {
        isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
        },
        methods: {
            logout: function () {
                this.$store.dispatch('logout')
                .then(() => {
                this.$router.push('/login')
                })
            }
        },
        mounted(){
            this.logout();
        }
    }
</script>